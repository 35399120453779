  // Dependencies
import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { useSpring, animated } from 'react-spring';
// Algolia
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  connectHits,
  connectSearchBox,
  connectStateResults
} from 'react-instantsearch-dom';
// Hooks
import useDebounce from "../hooks/useDebounce";
import useRandomItem from "../hooks/useRandomItem";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import useSettings from "../hooks/useSettings";
import useWindowSize from "../hooks/useWindowSize";
// Components
import Accordian from "../components/Accordian";
import Card from "../components/Card";
import { SearchIcon } from "../components/Icons";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Markdown from "../components/Markdown";
import Stamp from "../components/Stamp";
import AnnotatedText from "../components/AnnotatedText";
import { HeaderActionsContext } from "../components/Store";

/* ------------------------
Algolia serach client
*/
const searchClient = algoliasearch('B04H9FVBLK', 'c18ccaff460ed281f5252f29f826aa82');

/* ------------------------
<SearchInput /> — text input search field
*/
const SearchInput = connectSearchBox((props) => {

  // Content
  const { refine, currentRefinement, onFocus, onBlur, inputRef, isOpen, setOpen } = props;
  const [value, set] = useState("");
  const debouncedSearchTerm = useDebounce(value, 1750);

  // UX
  // useEffect(() => {
  //   refine(debouncedSearchTerm);
  //   inputRef.current.blur();
  //   // eslint-disable-next-line
  // }, [debouncedSearchTerm]);

  const observeCurrentRefinement = useCallback(() => {
    if (currentRefinement !== value) {
      set(currentRefinement)
    }
  }, [currentRefinement, value, set]);
  useEffect(observeCurrentRefinement, [currentRefinement]);

  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      const findSearchTerm = location.search.split("?");
      refine(decodeURI(findSearchTerm[1]));
    }
    // eslint-disable-next-line
  }, [location])

  // UI
  const handleChange = (event) => {
    set(event.target.value)
  };

  const handleClose = () => {
    set("")
    setOpen(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    refine(value)
    inputRef.current.blur();
  }

  return (
    <form action="/" onSubmit={handleSubmit}>
      <div className="search-input-header-contents">
        <div className="h1 search-input-field">
          <SearchIcon />
          <input type="text" value={value} onChange={handleChange} placeholder="SEARCH" role="search" onFocus={onFocus} onBlur={onBlur} ref={inputRef} />
          <input type="submit" style={{ visibility: 'hidden', height: 0, width: 0 }} />
        </div>
        {isOpen && (
          <button className="search-close-btn h1" onClick={handleClose}>{`(Close)`}</button>
        )}
      </div>
    </form>
  )
});

/* ------------------------
<SearchTerm /> — click on an element and add a term to the search query
*/
const SearchTerm = connectSearchBox(({ className, style, term, onClick, children, refine }) => {
  const handleClick = () => {
    !onClick || onClick()
    refine(term)
  }
  return (
    term && (
      <button className={className} style={style} onClick={handleClick}>{children}</button>
    )
  )
});

/* ------------------------
<Question /> — Question UI item which on click refines search
*/
const Question = ({ term, standfirst, addToSearchInput, colors, setStandfirstCopy }) => {

  const color = useRandomItem(colors).backgroundColor;

  const handleClick = () => {
    setStandfirstCopy(standfirst.text)
  }

  return (
    <div className="question p2" style={{
      color: color
    }}>
      <SearchTerm term={term} onClick={handleClick} addToSearchInput={addToSearchInput}>{term}</SearchTerm>
    </div>
  )
}

/* ------------------------
<Subfolder /> — UI element for hide/showing contents for search folder
*/


const Subfolder = ({ label, columnClassname, color, isOpen, children }) => {

  return (
    <div className="explore-section">
      <Accordian label={() => label.json ? (
        <AnnotatedText className="p2 upcase dark" richText={label} color={color} />
      ) : (
        <h1>{label}</h1>
      )}>
        <div className={columnClassname}>
          {children}
        </div>
      </Accordian>
    </div>
  )
}



/* ------------------------
<Hits /> — maps search results to Card elements (has to be child of <Results />)
*/

const Hits = connectHits(({ hits, clearSearch, advice }) => {

  useLockBodyScroll();

  return (
    <div className="results">
      {hits.map((entry, index) => {
        const findCard = advice.find(card => card.slug === entry.slug);
        return (
          findCard && (
            <div className="hit" key={`advice-result-${index}`}>
              <Card {...findCard} isSmall isLinked />
            </div>
          )
        )
      })}
    </div>
  )
});

/* ------------------------
<Results /> — returns search results, with condition that a search has been entered...
*/
const Results = connectStateResults(({ searchState, searchResults, children, isOpen, setOpen } ) => {
  const hasResults = searchResults && searchState.query && searchResults.hits.length > 0;

  useEffect(() => {
    if (searchState.query) {
      setOpen(true)
    }
  }, [searchState, setOpen])

  return children(searchResults, searchState, hasResults)
});

/* -------------------------------------------
<SearchResults /> — overlay for navigating and searching on results
*/

const Search = (props) => {

  // Content
  const { copy, standfirstCopy, setStandfirstCopy, advice } = props
  const { adviceCategories } = useSettings();

  // UX
  const ref = useRef();
  const inputRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const showHeader = useContext(HeaderActionsContext);

  useEffect(() => {
    showHeader(isOpen)
  }, [isOpen, showHeader])

  // UI
  const inputHandlers = {
    onFocus: () => {
      setFocused(true)
      setStandfirstCopy(false)
    },
    onBlur: () => setFocused(false)
  }

  // CSS
  const { height } = useWindowSize();
  const inputHeaderHeight = useCallback(node => {
    return node ? node.offsetHeight : 72
  }, []);

  const translateY = {
    open: height - inputHeaderHeight() + 2,
    closed: inputHeaderHeight() + 10,
    focused: height * 0.33
  };

  const css = {
    height: isOpen ? translateY.open : isFocused ? translateY.focused : translateY.closed
  };

  return (
    <div className={`search-results folder ${isOpen ? 'open' : ''} ${isFocused ? 'focus' : ''}`} ref={ref} style={css}>
      <section className="border search-input-header" ref={inputHeaderHeight}>
        <SearchInput {...inputHandlers} inputRef={inputRef} isOpen={isOpen} setOpen={setOpen} />
      </section>
      {/* Results */}
      <Results setOpen={setOpen}>
        {(searchResults, searchState, hasResults) => {

          return (
            <div>
              <div className="container innerx2">
                <div className="grid">
                  <div className="col-4">
                    {isOpen ? (
                      hasResults ? (
                        <div className="p2">
                          {standfirstCopy || `Showing ${searchResults.hits.length} results for "${searchState.query}"`}
                        </div>
                      ) : (
                        <Markdown field={copy.searchNoResults} className="p2 has-dark-links" />
                      )
                    ) : (
                      isFocused && (
                        <Markdown field={copy.searchDescription} className="p2 has-dark-links" />
                      )
                    )}
                  </div>
                  <div className="col-2" />
                </div>
              </div>
              {hasResults && isOpen && <Hits categories={adviceCategories} advice={advice} />}
            </div>
          )
        }}
      </Results>
    </div>
  )
}

/* ------------------------
Explore page — Page component passed to gatsby router
*/
const ExplorePage = ({ data }) => {

  // -------------
  // Content
  const { adviceCategories } = useSettings();
  const tags = data.allContentfulTag.nodes;
  const questions = data.allContentfulQuestion.nodes;
  const copy = data.allContentfulExplore.nodes[0];
  const [standfirstCopy, setStandfirstCopy] = useState(false);

  return (
    <Layout title={"Explore the advice"}>
      <Header />
      <InstantSearch searchClient={searchClient} indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}>
        <div className="container explore-page">
          <section className="grid page-section has-border" style={{ marginBottom: '8rem', marginTop: '2px' }}>
            <AnnotatedText className="col-4 p1 dark" richText={copy.introduction} color="#f65a5c" />
            <div className="col-2" />
          </section>

          <Subfolder label={copy.categoriesTitle} color={"#F6C544"}>
            <section className="grid">
              <Markdown field={copy.categoriesDescription} className="col-4 p2" />
              <div className="col-2" />
            </section>
            <div className="explore-stamps">
              {adviceCategories.map((category, index) => {
                return (
                  <div className="stamp" key={`stamp-${category.slug}-${index}`}>
                    <Link to={`/advice/${category.slug}`}>
                      <Stamp category={category} randomize />
                    </Link>
                  </div>
                )
              })}
            </div>
          </Subfolder>

          <Subfolder label={copy.tagsTitle} color={"#82C3C4"}>
            <section className="grid">
              <Markdown field={copy.tagsDescription} className="col-4 p2" />
              <div className="col-2" />
            </section>
            <div className="explore-tags">
              {tags.map((tag, index) => (
                <SearchTerm
                  className="p2"
                  style={{ color: tag.color, backgroundColor: tag.backgroundColor }}
                  term={tag.name}
                  key={`t-${tag.name}-${index}`}
                >
                  {`#${tag.name}`}
                </SearchTerm>
              ))}
            </div>
          </Subfolder>

          <Subfolder label={copy.questionsTitle} color={"#DFB5E4"}>
            <section className="grid">
              <Markdown field={copy.questionsDescription} className="col-4 p2" />
              <div className="col-2" />
            </section>
            <div className="explore-questions">
              {questions.map(({ question, slug, standfirst }, index) => {
                return (
                  <Question term={question} standfirst={standfirst} setStandfirstCopy={setStandfirstCopy} colors={adviceCategories} key={`q-${slug}-${index}`} />
                )
              })}
            </div>
          </Subfolder>
        </div>

        <Search copy={copy} standfirstCopy={standfirstCopy} setStandfirstCopy={setStandfirstCopy} advice={data.allContentfulAdvice.nodes} />

      </InstantSearch>
    </Layout>
  )
}

ExplorePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ExplorePage

export const ExplorePageQuery = graphql`
  query ExplorePageQuery {
    allContentfulExplore {
      nodes {
        introduction {
          json
        }
        # Browse categories
        categoriesTitle {
          json
        }
        categoriesDescription {
          childMarkdownRemark {
            html
          }
        }
        # Filter by tag
        tagsTitle {
          json
        }
        tagsDescription {
          childMarkdownRemark {
            html
          }
        }
        # Search by question
        questionsTitle {
          json
        }
        questionsDescription {
          childMarkdownRemark {
            html
          }
        }
        # Results
        searchDescription {
          childMarkdownRemark {
            html
          }
        }
        searchNoResults {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulAdvice {
      nodes {
        ...AdviceFragment
      }
    }
    allContentfulTag(sort: {fields: name, order: ASC}) {
      nodes {
        name
        slug
        color
        backgroundColor
      }
    }
    allContentfulQuestion(sort: {fields: slug, order: ASC}) {
      nodes {
        question
        slug
        standfirst {
          text: standfirst
        }
      }
    }
    allContentfulCategory {
      nodes {
        ...CategoryFragment
      }
    }
  }
`
